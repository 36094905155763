import { mapGetters, mapActions } from 'vuex'

const moduleName = 'debtorsreceipts'

export default {
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff'
    }),
    ...mapGetters(['licenceExpired'])
  },
  methods: {
    ...mapActions(moduleName, ['getStoreItem', 'saveSnapshot', 'clearSnapshots']),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' }),
    ...mapActions('advancereceipt', { setAdvanceReadOnlyView: 'setReadOnlyView' }),
    ...mapActions('debtoradjustments', { setDebtorAdjustmentReturnRoute: 'setReturnRoute', editStoreDebtorAdjustment: 'editStoreItem', setAdjustmentReadOnlyView: 'setReadOnlyView' }),
    ...mapActions('quickinvoices', { setQuickInvoiceReturnRoute: 'setReturnRoute', editStoreQuickInvoice: 'editStoreItem', setQuickInvoiceReadOnlyView: 'setReadOnlyView' }),
    ...mapActions('quotes', { setQuoteReturnRoute: 'setReturnRoute', setQuoteInvoiceReadOnlyView: 'setReadOnlyView' }),
    ...mapActions('miscquotes', { setMiscQuoteReturnRoute: 'setReturnRoute', setMiscQuoteInvoiceReadOnlyView: 'setReadOnlyView' })
  }
}
