<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="50"
    :min-height="50"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`mdi-printer`" />
        </span>
        <span>Print Debtor Statement</span>
      </div>
    </header>
    <section class="modal-card-body">
      <div class="tile is-ancestor is-parent">
        <div class="tile">
          <div class="tile is-parent is-half">
            <article class="tile is-child box detail-page-tile">
              <div class="columns is-gapless">
                <div class="column">
                  <div class="field">
                    <label class="label">Select Debtor</label>
                    <div class="field">
                      <input class="is-checkradio"
                        id="single"
                        type="radio"
                        name="debtor"
                        value="1"
                        v-model="debtorSelection"
                        @click="resetSelectedDebtors">
                      <label for="single">Print 1 Debtor</label>
                    </div>
                    <div class="field">
                      <input class="is-checkradio"
                        id="multiple"
                        type="radio"
                        name="debtor"
                        value="2"
                        v-model="debtorSelection"
                        @click="resetSelectedDebtors">
                      <label for="multiple">Multiple Debtors</label>
                    </div>
                    <div class="field">
                      <input class="is-checkradio"
                        id="all"
                        type="radio"
                        name="debtor"
                        value="0"
                        v-model="debtorSelection"
                        @click="resetSelectedDebtors">
                      <label for="all">All Debtors</label>
                    </div>
                    <div class="field">
                      <multiselect v-model="selectedDebtors"
                        deselect-label="Can't remove this value"
                        track-by="debtorId"
                        label="debtorName"
                        :options="debtors"
                        :multiple="debtorSelection === '2'"
                        :loading="isDebtorLoading"
                        :internal-search="false"
                        :disabled="debtorSelection === '0'"
                        :options-limit="debtorFilter.pageSize"
                        placeholder="Select Debtor"
                        @select="selectDebtor"
                        @remove="removeDebtor"
                        @search-change="getDebtorDropdownFilter"
                        :searchable="true"
                        :show-labels="false"
                        :allow-empty="true">
                        <template slot="option"
                          slot-scope="props">
                          <span>{{ props.option.debtorName }}</span>
                        </template>
                        <span class="has-text-danger"
                          slot="noResult">Debtor not found.</span>
                      </multiselect>
                    </div>
                  </div>
                  <label class="label">Invoice Date</label>
                  <div class="field">
                    <div class="columns">
                      <div class="column">
                        <v-date-picker v-model="selectedFromDate"
                          :masks="formats"
                          :attributes="attrs"
                          :locale="$userInfo.locale"
                          :popover="{ placement: 'top' }"
                          :model-config="{ timeAdjust: '00:00:00' }"
                          :timezone="$filters.getIanaTimezone()">
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="field">
                              <div class="control has-icons-left">
                                <input type="text"
                                  class="input"
                                  placeholder="Date From..."
                                  :value="inputValue"
                                  v-on="inputEvents">
                                <span class="icon is-small is-left">
                                  <i class="mdi mdi-calendar mdi-18px" />
                                </span>
                              </div>
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                      <div class="column">
                        <v-date-picker v-model="selectedToDate"
                          :masks="formats"
                          :attributes="attrs"
                          :locale="$userInfo.locale"
                          :popover="{ placement: 'top' }"
                          :model-config="{ timeAdjust: '23:59:59' }"
                          :timezone="$filters.getIanaTimezone()">
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="field">
                              <div class="control has-icons-left">
                                <input type="text"
                                  class="input"
                                  placeholder="Date To..."
                                  :value="inputValue"
                                  v-on="inputEvents">
                                <span class="icon is-small is-left">
                                  <i class="mdi mdi-calendar mdi-18px" />
                                </span>
                              </div>
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="print()"
        data-tooltip="Print">
        <span class="icon">
          <i class="mdi mdi-printer mdi-18px" />
        </span>
        <span>Print</span>
      </button>
      <button class="button tooltip"
        @click="close()"
        data-tooltip="Close">Close</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import _debounce from 'lodash.debounce'
import Multiselect from 'vue-multiselect'
import StoreMixin from './storeMixin'
import StoreUtil from '@/store/utils'
import Guid from '@/components/Guid'
import { DateTime } from 'luxon'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { Emailer } from '@/classes'
import { RemarkTypes } from '@/enums'
import DebtorListService from './DebtorListService'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'DebtorListPrintStatementModal',
  // inject: ['$vv'],
  components: {
    BaseModalEx,
    Multiselect
  },
  mixins: [StoreMixin, DateTimeFiltersMixin],
  props: {
    value: null,
    vehicle: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-file-export'
    },
    enableAttach: {
      type: Boolean,
      default: true
    },
    defaultEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: false,
      debtorSelection: '1',
      debtors: [],
      selectedDebtors: '',
      isDebtorLoading: false,
      debtorFilter: {
        debtorId: Guid.empty(),
        debtorName: '',
        resultType: 2,
        pageIndex: 1,
        pageSize: 50
      },
      dateFrom: null,
      dateTo: null,
      debtorDate: '',
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  watch: {
    selectedFromDate: function(newVal, oldVal) {
      if (newVal) {
        this.dateFrom = this.selectedFromDate.toISOString()
      } else {
        this.dateFrom = ''
      }
    },
    selectedToDate: function(newVal, oldVal) {
      if (newVal) {
        this.dateTo = this.selectedToDate.toISOString()
      } else {
        this.dateTo = ''
      }
    }
  },
  created() {
    if (this.dateFrom) {
      this.selectedFromDate = new Date(this.dateFrom)
    }
    if (this.dateTo) {
      this.selectedToDate = new Date(this.dateTo)
    }
    this.getDebtorDropdown()
    this.getDebtorDate()
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async print() {
      if ((this.debtorSelection === '1' || this.debtorSelection === '2') && this.selectedDebtors === '') {
        this.$notification.openNotificationWithType('danger', 'Debtor List Statement', 'Please select Debtor')
      } else if (this.dateFrom === null || this.dateTo === null) {
        this.$notification.openNotificationWithType('danger', 'Debtor List Statement', 'Invoice Date cannot be blank')
      } else {
        if (this.debtorSelection === '1') {
          this.printStatement(this.selectedDebtors.debtorId, this.selectedDebtors.debtorName)
        } else if (this.debtorSelection === '2') {
          for (let i = 0; i < this.selectedDebtors.length; i++) {
            this.printStatement(this.selectedDebtors[i].debtorId, this.selectedDebtors[i].debtorName)
          }
        } else {
          // print all debtors statement
          this.debtorFilter.resultType = 1
          this.debtors = await DebtorListService.getDebtorDropdownProto(this.debtorFilter)
          for (let i = 0; i < this.debtors.length; i++) {
            this.printStatement(this.debtors[i].debtorId, this.debtors[i].debtorName)
          }
        }
        // this.close()
      }
    },
    printStatement(debtorId, debtorName) {
      const params = {
        COMPANYNAME: encodeURIComponent(this.$userInfo.companyName),
        REPORTTITLE: 'STATEMENT',
        CompanyID: this.$userInfo.companyId,
        LIST: debtorId,
        FROMDATE: this.dateFrom === '' ? '' : DateTime.fromISO(this.dateFrom).toISODate(),
        TODATE: this.dateTo === '' ? '' : DateTime.fromISO(this.dateTo).toISODate(),
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }
      // Create KeyValuePairs and add to vuex for later retrieval by PrintPreviewEx component
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)
      // this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = debtorId
      emailer.remark = 'Debtor Statement'
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = 'Debtor Statement for ' + debtorName
      emailer.reportName = 'DebtorStatement'
      // this.addEmailer(emailer)
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_DebtorStatementMulti' },
        query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
    },
    async getDebtorDate() {
      this.debtorDate = await DebtorListService.getDebtorDate()
      this.selectedFromDate = new Date(this.debtorDate.fromDate)
      this.selectedToDate = new Date(this.debtorDate.toDate)
    },
    async getDebtorDropdown() {
      this.isDebtorLoading = true
      this.debtorFilter.resultType = 2
      this.debtors = await DebtorListService.getDebtorDropdownProto(this.debtorFilter)
      this.isDebtorLoading = false
    },
    getDebtorDropdownFilter: _debounce(async function(query) {
      this.isDebtorLoading = true
      this.debtorFilter.debtorName = query
      this.debtorFilter.resultType = 2
      this.debtors = await DebtorListService.getDebtorDropdownProto(this.debtorFilter)
      this.isDebtorLoading = false
    }, 500),
    async selectDebtor(selected) {
      this.debtorFilter.debtorId = selected.debtorId
      this.debtorFilter.debtorName = selected.debtorName
    },
    removeDebtor() {
      this.debtorFilter.debtorId = ''
      this.debtorFilter.debtorName = ''
      this.selectedDebtors = ''
      this.getDebtorDropdown()
    },
    resetSelectedDebtors() {
      this.selectedDebtors = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
