import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Inv No.', 'INVOICEREF', true, 'desc', 'left'),
  new TableColumn('Debtor Name', 'CUSTOMERNAME', true, 'asc', 'left'),
  new TableColumn('Inv Date', 'INVOICEDATE', true, 'asc', 'left'),
  new TableColumn('Balance', 'BALANCE', true, 'asc', 'right'),
  new TableColumn('Inv Excl. GST', 'TOTALEXGST', true, 'asc', 'right'),
  new TableColumn('GST', 'GST', true, 'asc', 'right'),
  new TableColumn('Inv Amount', 'TOTALINCGST', true, 'asc', 'right'),
  new TableColumn('Quote No.', 'QUOTENO', true, 'asc', 'left'),
  new TableColumn('Exp', 'exp', true, 'asc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns }
