/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.DebtorDropdownFilter = (function() {

    /**
     * Namespace DebtorDropdownFilter.
     * @exports DebtorDropdownFilter
     * @namespace
     */
    var DebtorDropdownFilter = {};

    DebtorDropdownFilter.DebtorDropdownFilterModel = (function() {

        /**
         * Properties of a DebtorDropdownFilterModel.
         * @memberof DebtorDropdownFilter
         * @interface IDebtorDropdownFilterModel
         * @property {string|null} [debtorId] DebtorDropdownFilterModel debtorId
         * @property {string|null} [debtorName] DebtorDropdownFilterModel debtorName
         * @property {number|null} [resultType] DebtorDropdownFilterModel resultType
         * @property {number|null} [pageIndex] DebtorDropdownFilterModel pageIndex
         * @property {number|null} [pageSize] DebtorDropdownFilterModel pageSize
         */

        /**
         * Constructs a new DebtorDropdownFilterModel.
         * @memberof DebtorDropdownFilter
         * @classdesc Represents a DebtorDropdownFilterModel.
         * @implements IDebtorDropdownFilterModel
         * @constructor
         * @param {DebtorDropdownFilter.IDebtorDropdownFilterModel=} [p] Properties to set
         */
        function DebtorDropdownFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DebtorDropdownFilterModel debtorId.
         * @member {string|null|undefined} debtorId
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @instance
         */
        DebtorDropdownFilterModel.prototype.debtorId = null;

        /**
         * DebtorDropdownFilterModel debtorName.
         * @member {string|null|undefined} debtorName
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @instance
         */
        DebtorDropdownFilterModel.prototype.debtorName = null;

        /**
         * DebtorDropdownFilterModel resultType.
         * @member {number|null|undefined} resultType
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @instance
         */
        DebtorDropdownFilterModel.prototype.resultType = null;

        /**
         * DebtorDropdownFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @instance
         */
        DebtorDropdownFilterModel.prototype.pageIndex = null;

        /**
         * DebtorDropdownFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @instance
         */
        DebtorDropdownFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * DebtorDropdownFilterModel _debtorId.
         * @member {"debtorId"|undefined} _debtorId
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @instance
         */
        Object.defineProperty(DebtorDropdownFilterModel.prototype, "_debtorId", {
            get: $util.oneOfGetter($oneOfFields = ["debtorId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorDropdownFilterModel _debtorName.
         * @member {"debtorName"|undefined} _debtorName
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @instance
         */
        Object.defineProperty(DebtorDropdownFilterModel.prototype, "_debtorName", {
            get: $util.oneOfGetter($oneOfFields = ["debtorName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorDropdownFilterModel _resultType.
         * @member {"resultType"|undefined} _resultType
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @instance
         */
        Object.defineProperty(DebtorDropdownFilterModel.prototype, "_resultType", {
            get: $util.oneOfGetter($oneOfFields = ["resultType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorDropdownFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @instance
         */
        Object.defineProperty(DebtorDropdownFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * DebtorDropdownFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @instance
         */
        Object.defineProperty(DebtorDropdownFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified DebtorDropdownFilterModel message. Does not implicitly {@link DebtorDropdownFilter.DebtorDropdownFilterModel.verify|verify} messages.
         * @function encode
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @static
         * @param {DebtorDropdownFilter.IDebtorDropdownFilterModel} m DebtorDropdownFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DebtorDropdownFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.debtorId != null && Object.hasOwnProperty.call(m, "debtorId"))
                w.uint32(10).string(m.debtorId);
            if (m.debtorName != null && Object.hasOwnProperty.call(m, "debtorName"))
                w.uint32(18).string(m.debtorName);
            if (m.resultType != null && Object.hasOwnProperty.call(m, "resultType"))
                w.uint32(24).int32(m.resultType);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(32).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(40).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified DebtorDropdownFilterModel message, length delimited. Does not implicitly {@link DebtorDropdownFilter.DebtorDropdownFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @static
         * @param {DebtorDropdownFilter.IDebtorDropdownFilterModel} message DebtorDropdownFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DebtorDropdownFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DebtorDropdownFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {DebtorDropdownFilter.DebtorDropdownFilterModel} DebtorDropdownFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DebtorDropdownFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.DebtorDropdownFilter.DebtorDropdownFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.debtorId = r.string();
                    break;
                case 2:
                    m.debtorName = r.string();
                    break;
                case 3:
                    m.resultType = r.int32();
                    break;
                case 4:
                    m.pageIndex = r.int32();
                    break;
                case 5:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a DebtorDropdownFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DebtorDropdownFilter.DebtorDropdownFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DebtorDropdownFilter.DebtorDropdownFilterModel} DebtorDropdownFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DebtorDropdownFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return DebtorDropdownFilterModel;
    })();

    return DebtorDropdownFilter;
})();

module.exports = $root;
