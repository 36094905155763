import FqApi from '@/services/fq-api'
import ProtoBufApi from '@/services/protobuf-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseDebtorListFilter(filter)
    const url = `debtorlists/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseDebtorListFilter(filter) {
    const entityProto = require('../../assets/proto/debtorlist/DebtorListFilter.proto')
    const filterMessage = entityProto.DebtorListFilter.DebtorListFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getAccessRoutes() {
    const url = 'accesssetup/companyaccess'
    const result = await FqApi.get(url)
    return result.data
  },
  async getDebtorDropdownProto(filter) {
    const serialised = this.serialiseDebtorDropdownFilter(filter)
    const result = await ProtoBufApi(`/debtorlists/protobuf/debtorlist/listcombo/${serialised}`)
    const proto = require('../../assets/proto/debtorlist/DebtorDropdown.proto')
    const message = await proto.DebtorDropdown.DebtorDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.assets
  },
  serialiseDebtorDropdownFilter(filter) {
    const entityProto = require('../../assets/proto/debtorlist/DebtorDropdownFilter.proto')
    const filterMessage = entityProto.DebtorDropdownFilter.DebtorDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getDebtorDate() {
    const url = 'debtorlists/debtordate'
    const result = await FqApi.get(url)
    result.data.fromDate = result.data.fromDate.split('.')[1] ? result.data.fromDate.split('.')[0] + 'Z' : result.data.fromDate
    result.data.toDate = result.data.toDate.split('.')[1] ? result.data.toDate.split('.')[0] + 'Z' : result.data.toDate
    return result.data
  }
}
