/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.DebtorDropdown = (function() {

    /**
     * Namespace DebtorDropdown.
     * @exports DebtorDropdown
     * @namespace
     */
    var DebtorDropdown = {};

    DebtorDropdown.DebtorDropdownModel = (function() {

        /**
         * Properties of a DebtorDropdownModel.
         * @memberof DebtorDropdown
         * @interface IDebtorDropdownModel
         * @property {Array.<DebtorDropdown.DebtorDropdownModel.IAsset>|null} [assets] DebtorDropdownModel assets
         */

        /**
         * Constructs a new DebtorDropdownModel.
         * @memberof DebtorDropdown
         * @classdesc Represents a DebtorDropdownModel.
         * @implements IDebtorDropdownModel
         * @constructor
         * @param {DebtorDropdown.IDebtorDropdownModel=} [p] Properties to set
         */
        function DebtorDropdownModel(p) {
            this.assets = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * DebtorDropdownModel assets.
         * @member {Array.<DebtorDropdown.DebtorDropdownModel.IAsset>} assets
         * @memberof DebtorDropdown.DebtorDropdownModel
         * @instance
         */
        DebtorDropdownModel.prototype.assets = $util.emptyArray;

        /**
         * Encodes the specified DebtorDropdownModel message. Does not implicitly {@link DebtorDropdown.DebtorDropdownModel.verify|verify} messages.
         * @function encode
         * @memberof DebtorDropdown.DebtorDropdownModel
         * @static
         * @param {DebtorDropdown.IDebtorDropdownModel} m DebtorDropdownModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DebtorDropdownModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.assets != null && m.assets.length) {
                for (var i = 0; i < m.assets.length; ++i)
                    $root.DebtorDropdown.DebtorDropdownModel.Asset.encode(m.assets[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified DebtorDropdownModel message, length delimited. Does not implicitly {@link DebtorDropdown.DebtorDropdownModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DebtorDropdown.DebtorDropdownModel
         * @static
         * @param {DebtorDropdown.IDebtorDropdownModel} message DebtorDropdownModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DebtorDropdownModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DebtorDropdownModel message from the specified reader or buffer.
         * @function decode
         * @memberof DebtorDropdown.DebtorDropdownModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {DebtorDropdown.DebtorDropdownModel} DebtorDropdownModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DebtorDropdownModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.DebtorDropdown.DebtorDropdownModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.assets && m.assets.length))
                        m.assets = [];
                    m.assets.push($root.DebtorDropdown.DebtorDropdownModel.Asset.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a DebtorDropdownModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DebtorDropdown.DebtorDropdownModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DebtorDropdown.DebtorDropdownModel} DebtorDropdownModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DebtorDropdownModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        DebtorDropdownModel.Asset = (function() {

            /**
             * Properties of an Asset.
             * @memberof DebtorDropdown.DebtorDropdownModel
             * @interface IAsset
             * @property {number|Long|null} [rowNumber] Asset rowNumber
             * @property {string|null} [debtorId] Asset debtorId
             * @property {string|null} [debtorName] Asset debtorName
             * @property {number|null} [totalRows] Asset totalRows
             */

            /**
             * Constructs a new Asset.
             * @memberof DebtorDropdown.DebtorDropdownModel
             * @classdesc Represents an Asset.
             * @implements IAsset
             * @constructor
             * @param {DebtorDropdown.DebtorDropdownModel.IAsset=} [p] Properties to set
             */
            function Asset(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Asset rowNumber.
             * @member {number|Long|null|undefined} rowNumber
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @instance
             */
            Asset.prototype.rowNumber = null;

            /**
             * Asset debtorId.
             * @member {string|null|undefined} debtorId
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @instance
             */
            Asset.prototype.debtorId = null;

            /**
             * Asset debtorName.
             * @member {string|null|undefined} debtorName
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @instance
             */
            Asset.prototype.debtorName = null;

            /**
             * Asset totalRows.
             * @member {number|null|undefined} totalRows
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @instance
             */
            Asset.prototype.totalRows = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * Asset _rowNumber.
             * @member {"rowNumber"|undefined} _rowNumber
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @instance
             */
            Object.defineProperty(Asset.prototype, "_rowNumber", {
                get: $util.oneOfGetter($oneOfFields = ["rowNumber"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Asset _debtorId.
             * @member {"debtorId"|undefined} _debtorId
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @instance
             */
            Object.defineProperty(Asset.prototype, "_debtorId", {
                get: $util.oneOfGetter($oneOfFields = ["debtorId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Asset _debtorName.
             * @member {"debtorName"|undefined} _debtorName
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @instance
             */
            Object.defineProperty(Asset.prototype, "_debtorName", {
                get: $util.oneOfGetter($oneOfFields = ["debtorName"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Asset _totalRows.
             * @member {"totalRows"|undefined} _totalRows
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @instance
             */
            Object.defineProperty(Asset.prototype, "_totalRows", {
                get: $util.oneOfGetter($oneOfFields = ["totalRows"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified Asset message. Does not implicitly {@link DebtorDropdown.DebtorDropdownModel.Asset.verify|verify} messages.
             * @function encode
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @static
             * @param {DebtorDropdown.DebtorDropdownModel.IAsset} m Asset message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Asset.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.rowNumber != null && Object.hasOwnProperty.call(m, "rowNumber"))
                    w.uint32(8).int64(m.rowNumber);
                if (m.debtorId != null && Object.hasOwnProperty.call(m, "debtorId"))
                    w.uint32(18).string(m.debtorId);
                if (m.debtorName != null && Object.hasOwnProperty.call(m, "debtorName"))
                    w.uint32(26).string(m.debtorName);
                if (m.totalRows != null && Object.hasOwnProperty.call(m, "totalRows"))
                    w.uint32(32).int32(m.totalRows);
                return w;
            };

            /**
             * Encodes the specified Asset message, length delimited. Does not implicitly {@link DebtorDropdown.DebtorDropdownModel.Asset.verify|verify} messages.
             * @function encodeDelimited
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @static
             * @param {DebtorDropdown.DebtorDropdownModel.IAsset} message Asset message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Asset.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Asset message from the specified reader or buffer.
             * @function decode
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {DebtorDropdown.DebtorDropdownModel.Asset} Asset
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Asset.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.DebtorDropdown.DebtorDropdownModel.Asset();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.rowNumber = r.int64();
                        break;
                    case 2:
                        m.debtorId = r.string();
                        break;
                    case 3:
                        m.debtorName = r.string();
                        break;
                    case 4:
                        m.totalRows = r.int32();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes an Asset message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof DebtorDropdown.DebtorDropdownModel.Asset
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {DebtorDropdown.DebtorDropdownModel.Asset} Asset
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Asset.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return Asset;
        })();

        return DebtorDropdownModel;
    })();

    return DebtorDropdown;
})();

module.exports = $root;
