<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                type="text"
                placeholder="Invoice No"
                v-model="innerValue.invoiceNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                type="text"
                placeholder="Debtor Name"
                v-model="innerValue.debtorName"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                type="text"
                placeholder="Quote No"
                v-model="innerValue.quoteNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div v-if="innerValue.filterType === 2"
            class="field">
            <div class="field">
              <v-date-picker v-model="selectedFromDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                :model-config="{ timeAdjust: '00:00:00' }"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Date From..."
                        :disabled="true"
                        :value="inputValue"
                        v-on="inputEvents">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="field">
              <v-date-picker v-model="selectedToDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                :model-config="{ timeAdjust: '23:59:59' }"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Date To..."
                        :disabled="true"
                        :value="inputValue"
                        v-on="inputEvents">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div v-else
            class="field">
            <div class="field">
              <v-date-picker v-model="selectedFromDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                :model-config="{ timeAdjust: '00:00:00' }"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Date From..."
                        :disabled="false"
                        :value="inputValue"
                        v-on="inputEvents">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="field">
              <v-date-picker v-model="selectedToDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                :model-config="{ timeAdjust: '23:59:59' }"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Date To..."
                        :disabled="false"
                        :value="inputValue"
                        v-on="inputEvents">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>

          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="innerValue.exported">
                  <option value="B">-- Exported Inv. Status --</option>
                  <option value="E">Exported</option>
                  <option value="U">Not Exported</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <div class="control">
              <input class="is-checkradio"
                id="1"
                type="radio"
                name="filterType"
                :value="1"
                v-model="innerValue.filterType">
              <label for="1">Invoices with Non Zero Balance Only</label>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="is-checkradio"
                id="2"
                type="radio"
                name="filterType"
                :value="2"
                v-model="innerValue.filterType">
              <label for="2">All Invoices within Last 6 Months</label>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="is-checkradio"
                id="0"
                type="radio"
                name="filterType"
                :value="0"
                v-model="innerValue.filterType">
              <label for="0">All Invoices</label>
            </div>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>
<script>
import AppSideMenu from '@/components/AppSideMenu'
import { mask } from 'vue-the-mask'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'DebtorListListSideMenu',
  components: {
    AppSideMenu
  },
  directives: {
    mask
  },
  props: {
    filter: null,
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectedActive: 1,
      stateList: null,
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {},
  watch: {
    'innerValue.filterType': function(newVal, oldVal) {
      if (newVal === 2) {
        this.innerValue.dateFrom = ''
        this.innerValue.dateTo = ''
        this.selectedFromDate = null
        this.selectedToDate = null
      }
    },
    selectedFromDate: function(newVal, oldVal) {
      if (newVal) {
        this.innerValue.dateFrom = this.selectedFromDate.toISOString()
      } else {
        this.innerValue.dateFrom = ''
      }
    },
    selectedToDate: function(newVal, oldVal) {
      if (newVal) {
        this.innerValue.dateTo = this.selectedToDate.toISOString()
      } else {
        this.innerValue.dateTo = ''
      }
    },
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function(newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    }
  },
  created() {
    if (this.innerValue.dateFrom) {
      this.selectedFromDate = new Date(this.value.dateFrom)
    }
    if (this.innerValue.dateTo) {
      this.selectedToDate = new Date(this.value.dateTo)
    }
  },
  mounted() {},
  beforeDestroy() {
    const elements = document.getElementsByClassName('datepicker')
    Array.from(elements).forEach((element, index, array) => {
      const parent = element.parentNode
      // console.log(parent.nodeType, element)
      if (parent.nodeName === 'BODY') {
        parent.removeChild(element)
      }
    })
  },
  methods: {
    onFilterClick() {
      this.innerValue.dateFrom = this.selectedFromDate !== null ? this.selectedFromDate.toISOString() : ''
      this.innerValue.dateTo = this.selectedToDate !== null ? this.selectedToDate.toISOString() : ''
      this.$emit('filter')
    },
    onResetClick() {
      this.selectedFromDate = null
      this.selectedToDate = null
      this.invoiceNo = ''
      this.debtorName = ''
      this.quoteNo = ''
      this.filterType = 1
      this.dateFrom = ''
      this.dateTo = ''
      this.$emit('reset')
    }
  }
}
</script>
